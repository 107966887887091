import gql from 'graphql-tag';

export const getListingCurationInfos = gql`
  query getListingCuration($curationId: String!) {
    productContainer {
      curation(curationId: $curationId) {
        curationId
        indexNo
        contentType
        data
        layoutType
        status
        description
      }
    }
  }
`;

export const getCurationGroups = gql`
  query getCurationGroupList {
    productContainer {
      curationGroups {
        id
        indexNo
        status
        description
        listingCurations {
          indexNo
        }
      }
    }
  }
`;

export const getCurationGroup = gql`
  query getCurationGroup($curationGroupId: String!) {
    productContainer {
      curationGroup(curationGroupId: $curationGroupId) {
        id
        indexNo
        status
        description
      }
    }
  }
`;

export const saveCurationGroup = gql`
  mutation saveCurationGroup(
    $listingCurationGroupParam: ListingCurationGroupParam!
  ) {
    saveListingCurationGroup(
      listingCurationGroupParam: $listingCurationGroupParam
    ) {
      id
      indexNo
      status
      description
    }
  }
`;

export const updateCurationGroup = gql`
  mutation updateCurationGroup(
    $listingCurationGroupParam: ListingCurationGroupParam!
  ) {
    updateListingCurationGroup(
      listingCurationGroupParam: $listingCurationGroupParam
    ) {
      id
      indexNo
      status
      description
    }
  }
`;

export const saveListingCuration = gql`
  mutation SaveListingCuration(
    $listingCurationParam: ListingCurationParamInput!
  ) {
    saveListingCuration(listingCurationParam: $listingCurationParam) {
      curationId
      contentType
      data
      indexNo
      layoutType
    }
  }
`;
