import { CommonState } from '@frientrip/domain';

export interface Curation {
  curation: ListingCuration[];
}

export interface ListingCuration {
  curationGroupId: string;
  indexNo: number;
  contentType: CurationContentType;
  data?: string;
  layoutType?: CurationLayoutType;
  status?: CommonState;
  description?: string;
}

export enum CurationContentType {
  SHORT_CUT = 'SHORT_CUT',
  COLLECTION = 'COLLECTION',
  RECENT_VIEW = 'RECENT_VIEW',
  RECENT_VIEW_COMMEND = 'RECENT_VIEW_COMMEND',
  BANNER_LIST = 'BANNER_LIST',
  MAGAZINE = 'MAGAZINE',
  LISTING_PRODUCT = 'LISTING_PRODUCT',
  RECOMMEND_LIST = 'RECOMMEND_LIST',
}

export enum CurationLayoutType {
  GLOBAL = 'global',
  MINIMAL = 'minimal',
  COVER = 'cover',
  CAROUSEL = 'carousel',
  HERO = 'hero',
  FULL_MINIMAL = 'full-minimal',
  FULL_HEADER = 'full-header',
}

export interface ListingCurationGroup {
  id: string;
  indexNo: number;
  status: CommonState;
  description: string;
  listingCurations: ListingCuration[];
}

export interface ListingCurationGroupParam {
  id: string;
  description?: string;
  status?: CommonState;
}

export interface ListingCurationParam {
  curationGroupId: string;
  infos: ListingCurationInfo[];
}

export interface ListingCurationInfo {
  contentType: CurationContentType;
  data?: string;
  indexNo: number;
  layoutType?: CurationLayoutType;
}
