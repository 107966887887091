



























































































































































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { CommonState } from '@frientrip/domain';
import {
  Curation,
  CurationContentType,
  ListingCuration,
  ListingCurationInfo,
  ListingCurationParam,
} from '@/domain/curation/models/curation';
import { Option, Select, Table, TableColumn } from 'element-ui';
import { getListingCurationInfos } from '@/domain/curation/graphqls/curationList';
import draggable from 'vuedraggable';
import { CurationService } from '@/domain/curation/CurationService';
import { apolloClient } from '@/apolloClient';

const curationService: CurationService = new CurationService(apolloClient);

export default ContainerMixin.extend({
  name: 'curation-list',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    draggable,
  },
  data(): {
    curationGroupId: string;
    lastIndexNo: number;
    productContainer: Curation;
    contentTypeItemTarget: string;
    contentTypeItemList: { text: string; value: string }[];
    shortCutItemList: { text: string; value: string }[];
    collectionItemList: { text: string; value: string }[];
    bannerItemList: { text: string; value: string }[];
    recentViewItemList: { text: string; value: string }[];
    etcItemList: { text: string; value: string }[];
    firstColumnDraggable: boolean;
    secondColumnDraggable: boolean;
  } {
    return {
      curationGroupId: this.$route.params.id,
      lastIndexNo: 0,
      productContainer: { curation: [] },
      contentTypeItemTarget: 'curation',
      contentTypeItemList: [
        { text: '숏컷', value: 'SHORT_CUT' },
        { text: '배너', value: 'BANNER_LIST' },
        { text: '컬렉션', value: 'COLLECTION' },
        { text: '매거진', value: 'MAGAZINE' },
        { text: '최근 본 프립', value: 'RECENT_VIEW' },
        { text: '최근 본 프립과 비교해 보세요', value: 'RECENT_VIEW_COMMEND' },
        { text: '새로 등록된 프립', value: 'LISTING_PRODUCT' },
        { text: '개인화 추천', value: 'RECOMMEND_LIST' },
      ],
      shortCutItemList: [
        { text: 'global', value: 'global' },
        { text: 'minimal', value: 'minimal' },
      ],
      collectionItemList: [
        { text: 'cover', value: 'cover' },
        { text: 'carousel', value: 'carousel' },
      ],
      bannerItemList: [
        { text: 'promotion', value: 'promotion' },
        { text: 'hero', value: 'hero' },
        { text: 'full-minimal', value: 'full-minimal' },
        { text: 'full-header', value: 'full-header' },
      ],
      recentViewItemList: [{ text: 'COMMON', value: 'COMMON' }],
      etcItemList: [{ text: '선택 불필요', value: 'etc' }],
      firstColumnDraggable: false,
      secondColumnDraggable: false,
    };
  },
  computed: {},
  methods: {
    disableDomElement(index: number): boolean {
      if (index == 0) {
        return !this.firstColumnDraggable;
      } else if (index == 1) {
        return !this.secondColumnDraggable;
      }
      return false;
    },
    getBackgroundColor(index: number): string {
      if (index == 0 && !this.firstColumnDraggable) {
        return 'unAbleTr';
      } else if (index == 1 && !this.secondColumnDraggable) {
        return 'unAbleTr';
      }
      return '';
    },
    checkHeader(value: any) {
      const errorMessage =
        '위치를 이동할 수 없습니다. 잠금 설정을 해제한 후 다시 시도해주세요.';
      if (value.draggedContext.index == 0 && !this.firstColumnDraggable) {
        this.showAlert({
          message: errorMessage,
          type: 'danger',
        });
        return false;
      } else if (
        value.draggedContext.index == 1 &&
        !this.secondColumnDraggable
      ) {
        this.showAlert({
          message: errorMessage,
          type: 'danger',
        });
        return false;
      }
      return true;
    },
    activeMoveToggle(index: number) {
      if (index == 0) {
        this.firstColumnDraggable = !this.firstColumnDraggable;
      } else if (index == 1) {
        this.secondColumnDraggable = !this.secondColumnDraggable;
      }
    },
    checkEmptyValues(): string {
      let errorMessage = '';
      try {
        this.productContainer.curation.map((value: ListingCuration, index) => {
          if (
            value.contentType == 'SHORT_CUT' ||
            value.contentType == 'COLLECTION' ||
            value.contentType == 'BANNER_LIST' ||
            value.contentType == 'RECENT_VIEW'
          ) {
            if (value.data == undefined) {
              errorMessage = `${
                index + 1
              } 번 항목에 "콘텐츠ID" 항목이 비어 있습니다.`;
              throw errorMessage;
            }
          }
        });
      } catch (error) {
        console.error(error);
      }

      if (errorMessage != '') return errorMessage;

      try {
        this.productContainer.curation.map((value: ListingCuration, index) => {
          if (
            value.contentType == 'SHORT_CUT' ||
            value.contentType == 'COLLECTION' ||
            value.contentType == 'BANNER_LIST'
          ) {
            if (value.layoutType == undefined) {
              errorMessage = `${
                index + 1
              } 번 항목에 "레이아웃" 항목이 비어 있습니다.`;
              throw errorMessage;
            }
          }
        });
      } catch (error) {
        console.error(error);
      }

      return errorMessage;
    },
    activeButtonToggle(indexNo: number) {
      const currentStatus = this.productContainer.curation[indexNo].status;
      if (currentStatus == 'ACTIVE')
        this.productContainer.curation[indexNo].status = CommonState.DELETED;
      else this.productContainer.curation[indexNo].status = CommonState.ACTIVE;
    },
    addColumn() {
      let maxIndexNo = 0;
      this.productContainer.curation.forEach((value: ListingCuration) => {
        if (value.indexNo > maxIndexNo) {
          maxIndexNo = value.indexNo;
        }
      });
      this.productContainer.curation.push({
        curationGroupId: this.curationGroupId,
        indexNo: maxIndexNo + 1,
        contentType: CurationContentType.SHORT_CUT,
        data: undefined,
        layoutType: undefined,
        status: CommonState.ACTIVE,
      });
    },
    deleteColumn(indexNo: number) {
      let newCuration: ListingCuration[] = [];
      this.productContainer.curation.forEach(value => {
        if (value.indexNo != indexNo) {
          newCuration.push(value);
        }
      });
      this.productContainer.curation = newCuration;
    },
    changeContentType(item: ListingCuration, index: number) {
      if (
        item.contentType == 'MAGAZINE' ||
        item.contentType == 'RECENT_VIEW_COMMEND' ||
        item.contentType == 'RECOMMEND_LIST'
      ) {
        this.productContainer.curation[index].data = undefined;
        this.productContainer.curation[index].layoutType = undefined;
      } else if (item.contentType == 'LISTING_PRODUCT') {
        this.productContainer.curation[index].layoutType = undefined;
        this.productContainer.curation[index].data = 'isNew';
      } else if (item.contentType == 'RECENT_VIEW') {
        this.productContainer.curation[index].data = 'COMMON';
      } else {
        this.productContainer.curation[index].layoutType = undefined;
        this.productContainer.curation[index].data = undefined;
      }
    },
    saveChanges() {
      const message: string = this.checkEmptyValues();
      if (message != '') {
        this.showAlert({
          message: message,
          type: 'danger',
        });
        return;
      }

      let listingCurationInfo: ListingCurationInfo[] =
        this.productContainer.curation.map((value: ListingCuration, index) => {
          return {
            contentType: value.contentType,
            data: value.data,
            indexNo: index,
            layoutType: value.layoutType,
            description: value.description,
            status: value.status,
          };
        });

      const listingCurationParam: ListingCurationParam = {
        curationGroupId: this.curationGroupId,
        infos: listingCurationInfo,
      };

      const result = curationService.saveListingCuration(listingCurationParam);
      console.log(result);
      result
        .then(value => {
          console.log(`저장 내용 사이즈 : ${value.length}`);
          this.$notify({
            title: '내용 저장 완료',
            message: '큐레이션 그룹 요청 내용을 저장 완료 되었습니다.',
            type: 'success',
          });
        })
        .catch(reason => {
          console.error(reason);
        });
    },
  },
  apollo: {
    productContainer: {
      query: getListingCurationInfos,
      variables(): { curationId: string } {
        return {
          curationId: this.curationGroupId,
        };
      },
    },
  },
});
