import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  getCurationGroup,
  saveCurationGroup,
  saveListingCuration,
  updateCurationGroup,
} from '@/domain/curation/graphqls/curationList';
import {
  ListingCuration,
  ListingCurationGroup,
  ListingCurationGroupParam,
  ListingCurationParam,
} from '@/domain/curation/models/curation';
import { CommonState } from '@frientrip/domain';

export class CurationService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async getCurationGroup(
    curationGroupId: string
  ): Promise<ListingCurationGroup> {
    if (curationGroupId == undefined) {
      console.log('신규 등록 페이지 유입');
      return {
        id: '',
        indexNo: 0,
        status: CommonState.ACTIVE,
        description: '',
        listingCurations: [],
      };
    }
    const res = await this.apollo.query({
      query: getCurationGroup,
      variables: { curationGroupId: curationGroupId },
    });
    return res.data.productContainer.curationGroup;
  }

  async updateCurationGroup(
    isUpdate: boolean,
    param: ListingCurationGroupParam
  ): Promise<ListingCurationGroup> {
    let mutation = saveCurationGroup;
    if (isUpdate) {
      mutation = updateCurationGroup;
    }
    const response = await this.apollo.mutate({
      mutation: mutation,
      variables: { listingCurationGroupParam: param },
    });

    if (isUpdate) {
      return response.data.updateListingCurationGroup;
    }
    return response.data.saveListingCurationGroup;
  }

  async saveListingCuration(
    param: ListingCurationParam
  ): Promise<ListingCuration[]> {
    const response = await this.apollo.mutate({
      mutation: saveListingCuration,
      variables: { listingCurationParam: param },
    });
    return response.data.saveListingCuration;
  }
}
